<template>
  <FormLayout @submit="submitForm">
    <LocalizationTabs
      v-slot="{ current }"
      v-model="activeCulture"
      :locales
      @add="addLocalization"
      @remove="removeLocalization"
    >
      <LocalizationSlot v-slot="{ locale }" :current :locales>
        <FormFieldTextInput
          :id="`localizations.${locale}.name`"
          :label="texts.models.surveyLink.name"
        />
      </LocalizationSlot>
    </LocalizationTabs>

    <div class="flex items-center gap-4">
      <FormFieldDateTime
        id="surveyStartDateTime"
        :label="texts.models.surveyLink.surveyStartDateTime"
        class="flex-1"
      />
      <FormFieldDateTime
        id="surveyEndDateTime"
        :label="texts.models.surveyLink.surveyEndDateTime"
        class="flex-1"
      />
    </div>

    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import { Culture } from "@/enums";
import settings from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { SurveyLinkEditFormValues } from "@/views/organize/activities/activity/hub/survey-links/components/SurveyLinkForm.types";
import { DateTime } from "luxon";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import * as yup from "yup";
import FormLayout from "@/components/common/form/FormLayout.vue";
import LocalizationSlot from "@/components/localization-tabs/LocalizationSlot.vue";
import { objectLocalizationSchemaOf } from "@/yup-extensions";

const props = defineProps<{
  formValues: SurveyLinkEditFormValues;
}>();

const emit = defineEmits<{
  submit: [values: SurveyLinkEditFormValues];
}>();

const form = useForm<SurveyLinkEditFormValues>({
  validationSchema: yup.object({
    localizations: objectLocalizationSchemaOf(
      yup.object({
        name: yup.string().max(256).required(),
        prospectAppDescription: yup.string().max(1024),
      }),
    ),
    surveyStartDateTime: yup.mixed<DateTime>(),
    surveyEndDateTime: yup
      .mixed<DateTime>()
      .isLaterThan(
        "surveyStartDateTime",
        texts.validation.endDateTimeMinimumStartDateTime,
      ),
  }),
});

const defaultCulture = settings.mainLanguage.locale.value as Culture;
const activeCulture = ref<Culture>(
  settings.mainLanguage.locale.value as Culture,
);
const locales = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

function addLocalization(culture: Culture) {
  form.setFieldValue("localizations", {
    ...form.values.localizations,
    [culture]: { name: "" },
  });
}

function removeLocalization(culture: Culture) {
  const newDict = { ...form.values.localizations };
  delete newDict[culture];
  form.setFieldValue("localizations", newDict);
}

watch(
  () => props.formValues,
  (values) => form.setValues(values),
  { immediate: true },
);

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>

<template>
  <Page>
    <ErrorComponent v-if="error" />
    <Section v-else>
      <template v-slot:heading>
        <SectionHeading
          :title="
            texts.navigationItems.mailing.steps.basicInformation.basicSection
              .title
          "
          :description="
            texts.navigationItems.mailing.steps.basicInformation.basicSection
              .description
          "
          :divider="false"
        />
      </template>
      <div class="flex flex-col gap-y-5">
        <FormFieldSelect
          id="type"
          :items="typeOptions"
          :label="texts.models.mailing.type"
          :displayMode="FormFieldDisplayMode.Row"
          :disabled="true"
        />
        <FormFieldSelect
          id="locale"
          :items="languageOptions"
          :label="texts.models.mailing.locale"
          :displayMode="FormFieldDisplayMode.Row"
          :disabled="languageOptions.length === 1"
        />
        <FormFieldTextInput
          id="name"
          :label="texts.models.mailing.name"
          :displayMode="FormFieldDisplayMode.Row"
        />
        <template
          v-if="contextData.mailingType.mailingType !== MailingType.General"
        >
          <FormFieldSingleSelectCombobox
            formFieldId="activityId"
            :label="texts.models.mailing.activity"
            :options="activityOptions"
            :formatOptionDisplayString="activityOptionDisplayFormatter"
            :searchFilter="activitySearchFilter"
            :displayMode="FormFieldDisplayMode.Row"
            :loading="false"
          />
        </template>
        <template
          v-if="
            contextData.mailingType.mailingType === MailingType.ActivityInvite
          "
        >
          <FormFieldSelect
            id="registrationLinkId"
            :items="registrationLinkOptions"
            :displayMode="FormFieldDisplayMode.Row"
            :label="texts.models.mailing.registrationLink"
            :disabled="registrationLinksLoading"
            allowUnset
          />
        </template>
        <template
          v-if="
            contextData.mailingType.mailingType ===
            MailingType.ActivityVisitedSurvey
          "
        >
          <FormFieldSelect
            id="surveyLinkId"
            :items="surveyLinkOptions"
            :displayMode="FormFieldDisplayMode.Row"
            :label="texts.models.mailing.surveyLink"
            :disabled="surveyLinksLoading"
            allowUnset
          />
        </template>
      </div>
    </Section>
    <Section>
      <template v-slot:heading>
        <SectionHeading
          :title="
            texts.navigationItems.mailing.steps.basicInformation.emailSection
              .title
          "
          :description="
            texts.navigationItems.mailing.steps.basicInformation.emailSection
              .description
          "
          :divider="false"
        />
      </template>
      <div class="flex flex-col gap-y-5">
        <FormFieldTextInput
          id="subject"
          :label="texts.models.mailing.subject"
          :displayMode="FormFieldDisplayMode.Row"
        />
        <FormFieldTextInput
          id="fromName"
          :label="texts.models.mailing.fromName"
          :displayMode="FormFieldDisplayMode.Row"
        />
        <FormFieldSelect
          id="fromEmailAddress"
          :label="texts.models.mailing.fromEmailAddress"
          :items="fromEmailOptions"
          :displayMode="FormFieldDisplayMode.Row"
        />
        <FormFieldSelect
          id="replyToEmailAddress"
          :label="texts.models.mailing.replyToEmailAddress"
          :items="replyToEmailAddressOptions"
          :displayMode="FormFieldDisplayMode.Row"
        />
      </div>
    </Section>
  </Page>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Culture, MailingType } from "@/enums";
import {
  ConceptMailingData,
  MailingContextData,
} from "@/views/mailings/mailing/MailingWizard.types";

import { useForm } from "vee-validate";
import * as yup from "yup";

import Page from "@/components/common/page/Page.vue";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import { SelectOption } from "@/components/common/select/SelectOption";
import logger from "@/plugins/logger";
import ErrorComponent from "@/components/common/error/Error.vue";
import { RegistrationLink } from "@/models/registrationLink";
import Notify from "@/utils/notify";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import FormFieldSingleSelectCombobox from "@/components/common/combobox/FormFieldSingleSelectCombobox.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { LocalizedActivityDTO } from "@/lib/eduConfigurationServiceClient";
import settings from "@/store/context/settings.context";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { ActivityStatus } from "@/models/activity";
import { ActivityVisitedSurveyLinkOverviewDTO } from "@/lib/eduConfigurationServiceClient";
import texts from "@/utils/texts";

const emit = defineEmits([
  "forwardNavigationValidated",
  "backNavigationValidated",
  "saveValidated",
]);

const props = defineProps<{
  isNavigatingBack: boolean;
  isNavigatingForward: boolean;
  isSaving: boolean;
  data: ConceptMailingData;
  contextData: MailingContextData;
}>();

const error = ref<boolean>(false);

// Form
const form = useForm({
  validationSchema: yup.object({
    type: yup.string().max(256).required(),
    locale: yup.mixed<Culture>().required(),
    name: yup.string().max(256).required(),
    activityId: yup
      .string()
      .max(256)
      .when("type", {
        is: MailingType.General,
        then: (schema) => schema,
        otherwise: (schema) => schema.required(),
      }),
    registrationLinkId: yup.string().when("type", {
      is: MailingType.ActivityInvite,
      then: (schema) => schema.max(36).required(),
    }),
    surveyLinkId: yup.string().when("type", {
      is: MailingType.ActivityVisitedSurvey,
      then: (schema) => schema.max(36).required(),
    }),
    subject: yup.string().max(256).required(),
    fromName: yup.string().max(256).required(),
    fromEmailAddress: yup.string().max(256).required(),
    replyToEmailAddress: yup.string().max(256).required(),
  }),
});

const registrationLinks = ref<RegistrationLink[]>([]);
const registrationLinksLoading = ref<boolean>(false);
const registrationLinkOptions = computed(() =>
  registrationLinks.value.map((link) => ({
    value: link.id,
    label: link.name ?? "",
  })),
);

const fetchRegistrationLinksForActivityId = async (activityId: string) => {
  registrationLinksLoading.value = true;
  registrationLinks.value = [];

  return eduConfigurationServiceClient
    .getAllRegistrationLinksWithRegistrationCounts(activityId)
    .then((links) => (registrationLinks.value = links))
    .catch((e) => {
      logger.error(e);

      error.value = true;
    })
    .finally(() => {
      registrationLinksLoading.value = false;
    });
};

const surveyLinks = ref<ActivityVisitedSurveyLinkOverviewDTO[]>([]);
const surveyLinksLoading = ref<boolean>(false);
const surveyLinkOptions = computed(() =>
  surveyLinks.value.map((link) => ({
    value: link.id,
    label: link.name ?? "",
  })),
);

const fetchSurveyLinksForActivityId = async (activityId: string) => {
  surveyLinksLoading.value = true;
  surveyLinks.value = [];

  return eduConfigurationServiceClient
    .getSurveyLinkOverview(activityId)
    .then((links) => (surveyLinks.value = links))
    .catch((e) => {
      logger.error(e);

      error.value = true;
    })
    .finally(() => {
      surveyLinksLoading.value = false;
    });
};

watch(
  () => form.values.activityId,
  async (newValue) => {
    if (!newValue) {
      return;
    }

    if (
      props.contextData.mailingType.mailingType === MailingType.ActivityInvite
    ) {
      const regLinkId = form.values.registrationLinkId;
      await fetchRegistrationLinksForActivityId(newValue);
      if (
        regLinkId &&
        !registrationLinks.value.find((link) => link.id === regLinkId)
      ) {
        form.setValues({ registrationLinkId: undefined });
      }
    }

    if (
      props.contextData.mailingType.mailingType ===
      MailingType.ActivityVisitedSurvey
    ) {
      const surveyLinkId = form.values.surveyLinkId;
      await fetchSurveyLinksForActivityId(newValue);
      if (
        surveyLinkId &&
        !surveyLinks.value.find((link) => link.id === surveyLinkId)
      ) {
        form.setValues({ surveyLinkId: undefined });
      }
    }
  },
  { immediate: true },
);

const internalData = computed(() => props.data);

const setFormValues = () => {
  form.setValues({
    type: internalData.value.type,
    locale: internalData.value.locale,
    name: internalData.value.name,
    activityId: internalData.value.activity?.id,
    registrationLinkId: internalData.value.registrationLink?.id,
    surveyLinkId: internalData.value.surveyLink?.id,
    subject: internalData.value.subject,
    fromName: internalData.value.fromName,
    fromEmailAddress: internalData.value.fromEmailAddress,
    replyToEmailAddress: internalData.value.replyToEmailAddress,
  });
};

const setInternalValues = () => {
  internalData.value.type = form.values.type as MailingType;
  internalData.value.locale = form.values.locale;
  internalData.value.name = form.values.name;
  internalData.value.activity = props.contextData.activities.find(
    (activity) => activity.id === form.values.activityId,
  );
  internalData.value.registrationLink = registrationLinks.value.find(
    (link) => link.id === form.values.registrationLinkId,
  );
  internalData.value.surveyLink = surveyLinks.value.find(
    (link) => link.id === form.values.surveyLinkId,
  );
  internalData.value.subject = form.values.subject;
  internalData.value.fromName = form.values.fromName;
  internalData.value.fromEmailAddress = form.values.fromEmailAddress;
  internalData.value.replyToEmailAddress = form.values.replyToEmailAddress;
};

const validateFormAndSetInternalValues = async (silent: boolean = false) => {
  setInternalValues();
  const validate = await form.validate({
    mode: silent ? "silent" : "force",
  });

  return validate.valid;
};

setFormValues();

// Navigation
watch(
  () => props.isNavigatingForward,
  async (value) => {
    await waitForRegistrationLinksLoaded();
    if (value) {
      const isValid = await validateFormAndSetInternalValues();
      emit("forwardNavigationValidated", isValid);
    }
  },
);

watch(
  () => props.isNavigatingBack,
  async (value) => {
    await waitForRegistrationLinksLoaded();
    if (value) {
      emit("backNavigationValidated", true);
    }
  },
);

watch(
  () => props.isSaving,
  async (value) => {
    await waitForRegistrationLinksLoaded();
    if (value) {
      const canSave = !!form.values.type && !!form.values.name;

      if (!canSave) {
        Notify.warning(texts.navigationItems.mailing.invalid);
      } else {
        await validateFormAndSetInternalValues(true);
      }
      emit("saveValidated", canSave);
    }
  },
);

async function waitForRegistrationLinksLoaded() {
  // Work around for navigating before registration links are properly loaded
  while (registrationLinksLoading.value) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
}

// Select Options
const typeOptions: Array<SelectOption> = [
  {
    value: MailingType.General,
    label: texts.enums.mailingType[MailingType.General],
  },
  {
    value: MailingType.Activity,
    label: texts.enums.mailingType[MailingType.Activity],
  },
  {
    value: MailingType.ActivityReminder,
    label: texts.enums.mailingType[MailingType.ActivityReminder],
  },
  {
    value: MailingType.ActivityInvite,
    label: texts.enums.mailingType[MailingType.ActivityInvite],
  },
  {
    value: MailingType.ActivityVisitedSurvey,
    label: texts.enums.mailingType[MailingType.ActivityVisitedSurvey],
  },
];

const languageOptions: Array<SelectOption> = settings.availableLanguages.map(
  (language) => ({
    value: language.locale.value,
    label: language.name,
  }),
);

const fromEmailOptions: Array<SelectOption> =
  props.contextData.fromEmailAddresses.map((fromEmailAddress) => ({
    value: fromEmailAddress.emailAddress,
    label: fromEmailAddress.emailAddress,
  }));

const replyToEmailAddressOptions: Array<SelectOption> =
  props.contextData.replyToEmailAddresses.map((replyToEmailAddress) => ({
    value: replyToEmailAddress.emailAddress,
    label: replyToEmailAddress.emailAddress,
  }));

if (
  props.contextData.mailingType.mailingType == MailingType.ActivityInvite &&
  props.data.activity
) {
  fetchRegistrationLinksForActivityId(props.data.activity.id);
}

const activityOptions = computed(() => {
  if (
    form.values.type === MailingType.Activity ||
    form.values.type === MailingType.ActivityReminder ||
    form.values.type === MailingType.ActivityInvite
  ) {
    return props.contextData.activities.filter(
      (activity) => activity.status !== ActivityStatus.finished,
    );
  } else {
    return props.contextData.activities;
  }
});

const activitySearchFilter = (
  activity: LocalizedActivityDTO,
  query: string,
) => {
  return activity.name?.toLowerCase().includes(query.toLowerCase()) ?? false;
};

const activityOptionDisplayFormatter = (activity: LocalizedActivityDTO) => {
  return `${activity.name ?? ""} (${activity.startDateTime.toISODate()})`;
};
</script>

<template>
  <Async :state="state">
    <template v-slot:failure>
      <ErrorComponent />
    </template>
    <template v-slot:loading>
      <Loader />
    </template>
    <template v-slot>
      <form class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
          <h2 class="text-lg font-semibold text-deepteal-700">
            {{ anonymizationTexts.title }}
          </h2>
          <span class="text-sm font-light text-deepteal-700">
            {{ anonymizationTexts.description }}
          </span>
        </div>
        <Button
          v-if="showInput === false"
          @click="
            showInput = true;
            form.setFieldValue(fieldIds.anonymizationPeriodInMonths, 12);
          "
        >
          {{ anonymizationTexts.createButton }}</Button
        >
        <div v-show="showInput" class="flex flex-col gap-4">
          <span class="text-sm font-semibold text-deepteal-700">
            {{ anonymizationTexts.periodLabel }}
          </span>
          <div
            class="flex flex-col items-center gap-2 sm:flex-row sm:items-start"
          >
            <FormFieldTextInput
              :id="fieldIds.anonymizationPeriodInMonths"
              :type="TextInputType.NUMBER"
              :displayMode="FormFieldDisplayMode.Column"
              min="12"
              class="flex-grow"
            />
            <Button
              leadingIcon="close"
              :color="Color.White"
              :size="ButtonSize.sm"
              @click="
                form.setFieldValue(
                  fieldIds.anonymizationPeriodInMonths,
                  undefined,
                )
              "
              >{{ texts.actions.clear }}</Button
            >
          </div>
          <Button
            :size="ButtonSize.sm"
            :disabled="
              form.values.anonymizationPeriodInMonths === intitialPeriod
            "
            @click="confirmModalVisible = true"
            >{{ anonymizationTexts.saveButton }}</Button
          >
        </div>
        <Modal
          v-model:visible="confirmModalVisible"
          :type="ModalType.warning"
          :title="anonymizationTexts.modal.title"
        >
          <template v-slot:description>
            <div class="flex flex-col gap-3 text-sm text-gray-600">
              <p>
                {{ anonymizationTexts.modal.description }}
              </p>
              <p v-if="form.values.anonymizationPeriodInMonths">
                {{
                  interpolate(
                    anonymizationTexts.modal.description2,
                    form.values.anonymizationPeriodInMonths.toString(),
                  )
                }}
              </p>
              <p v-else>
                {{ anonymizationTexts.modal.descriptionNoPeriod }}
              </p>
            </div>
          </template>
          <template v-slot:content>
            <Alert
              v-if="anonymizableProspectCount != undefined"
              :color="Color.Warning"
            >
              <span>
                {{
                  interpolate(
                    anonymizationTexts.numberOfAnonymizableProspects,
                    anonymizableProspectCount?.toString() ?? "",
                    anonymizationFromDateTime?.toLocaleString(
                      DateTime.DATE_FULL,
                    ) ?? "",
                  )
                }}
              </span>
            </Alert>
          </template>
          <template v-slot:buttons>
            <div class="flex flex-col gap-3 sm:flex-row-reverse">
              <Button
                :loading="loading"
                :color="Color.Warning"
                @click="submit"
                >{{ texts.actions.apply }}</Button
              >
              <ButtonCancel @click="cancel" />
            </div>
          </template>
        </Modal>
      </form>
    </template>
  </Async>
</template>

<script setup lang="ts">
import Loader from "@/components/common/loader/Loader.vue";
import Async from "@/components/common/async/Async.vue";
import ErrorComponent from "@/components/common/error/Error.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import { TextInputType } from "@/components/common/text-input/TextInput.types";
import { useAsyncState } from "@/components/common/async/Async.types";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { computed, ref, watch } from "vue";
import { useForm } from "vee-validate";
import {
  AnonymizationPeriodSettingsFormValues,
  convertToDTO,
  validationSchema,
} from "@/views/settings/general/AnonymizationPeriodSettings.types";
import { TenantAnonymizationPeriodDTO } from "@/lib/eduConfigurationServiceClient";
import { interpolate } from "@/dictionary";
import logger from "@/plugins/logger";
import Alert from "@/components/common/alert/Alert.vue";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import { fields } from "@/utils/miscellaneous";
import Modal from "@/components/common/modal/Modal.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import Button from "@/components/common/button/Button.vue";
import { Color } from "@/enums";
import { ModalType } from "@/components/common/modal/Modal.types";
import { ButtonSize } from "@/components/common/button/Button.types";
import { DateTime } from "luxon";

const confirmModalVisible = ref(false);

const fieldIds = fields<AnonymizationPeriodSettingsFormValues>();
const form = useForm<AnonymizationPeriodSettingsFormValues>({
  validationSchema,
});

const anonymizationTexts =
  texts.navigationItems.manage.settings.general.anonymization;
const loading = ref(false);
const anonymizableProspectCount = ref<number | undefined>(undefined);
let intitialPeriod = ref<number | undefined>();
const showInput = ref<boolean | undefined>();

const currentDate = DateTime.now();
const anonymizationFromDateTime = computed(() => {
  return form.values.anonymizationPeriodInMonths
    ? currentDate.minus({ months: form.values.anonymizationPeriodInMonths })
    : undefined;
});

const { state, handler } = useAsyncState(async () => {
  const dto = await eduConfigurationServiceClient.getAnonymizationPeriod();
  intitialPeriod.value = dto.anonymizationPeriodInMonths;
  showInput.value = dto.anonymizationPeriodInMonths != undefined;
  form.setValues({
    anonymizationPeriodInMonths: dto.anonymizationPeriodInMonths,
  });
});

watch(
  () => form.values.anonymizationPeriodInMonths,
  async (newPeriod) => {
    if (newPeriod === undefined || newPeriod == intitialPeriod.value) {
      anonymizableProspectCount.value = undefined;
      return;
    }

    try {
      anonymizableProspectCount.value =
        await eduConfigurationServiceClient.countAnonymizableProspects(
          newPeriod,
        );
    } catch {
      anonymizableProspectCount.value = undefined;
    }
  },
);

const submit = async () => {
  const result = await form.validate();

  if (result.valid) {
    try {
      loading.value = true;

      const dto = convertToDTO(
        form.values as AnonymizationPeriodSettingsFormValues,
      );

      await eduConfigurationServiceClient.updateAnonymizationPeriod(
        new TenantAnonymizationPeriodDTO(dto),
      );

      intitialPeriod.value = dto.anonymizationPeriodInMonths;
      anonymizableProspectCount.value = undefined;

      Notify.success(anonymizationTexts.notifications.success);
    } catch (e) {
      Notify.failure(anonymizationTexts.notifications.failure);
      logger.error(e);
    } finally {
      loading.value = false;
      confirmModalVisible.value = false;
    }
  } else {
    Notify.failure(anonymizationTexts.notifications.failure);
  }
};

const cancel = () => {
  confirmModalVisible.value = false;
  // Wait for the modal to close before resetting the form
  setTimeout(() => {
    form.setValues({
      anonymizationPeriodInMonths: intitialPeriod.value,
    });
  }, 300);
};

handler();
</script>

<template>
  <FormLayout @submit="submitForm">
    <LocalizationTabs
      v-slot="{ current }"
      v-model="activeCulture"
      :locales
      @add="addLocalization"
      @remove="removeLocalization"
    >
      <LocalizationSlot v-slot="{ locale }" :current :locales>
        <FormFieldTextInput
          :id="`localizations.${locale}.name`"
          :label="texts.models.registrationLink.name"
        />
      </LocalizationSlot>
    </LocalizationTabs>

    <FormFieldToggle
      id="isVisibleForProspects"
      :label="
        texts.navigationItems.organize.activity.form.isVisibleForProspects
      "
    />

    <div class="flex flex-col gap-1">
      <FormFieldSelect
        id="confirmationEmailTemplateId"
        :label="texts.models.registrationLink.confirmationEmailTemplate"
        :items="
          settings.emailTemplates.map((et) => ({
            label: et.name,
            value: et.id,
          }))
        "
        allowUnset
      />
      <RouterLinkAuth
        v-if="form.values.confirmationEmailTemplateId"
        :to="{
          name: RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE,
          params: { id: form.values.confirmationEmailTemplateId },
        }"
      >
        <span class="text-sm text-emerald-500 underline hover:text-emerald-700">
          {{ componentTexts.edit.slideOver.detailSection.editEmailTemplate }}
        </span>
      </RouterLinkAuth>
    </div>

    <div class="flex items-center gap-4">
      <FormFieldDateTime
        id="registrationStartDateTime"
        :label="texts.models.registrationLink.registrationStartDateTime"
        class="flex-1"
      />
      <FormFieldDateTime
        id="registrationEndDateTime"
        :label="texts.models.registrationLink.registrationEndDateTime"
        class="flex-1"
      />
    </div>

    <template v-slot:actions>
      <slot></slot>
    </template>
  </FormLayout>
</template>

<script setup lang="ts">
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { Culture } from "@/enums";
import { RouteNamesSettings } from "@/router/routeNames";
import settings from "@/store/context/settings.context";
import texts from "@/utils/texts";
import { RegistrationLinkEditFormValues } from "@/views/organize/activities/activity/registration-links/components/RegistrationLinkForm.types";
import { DateTime } from "luxon";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import * as yup from "yup";
import FormLayout from "@/components/common/form/FormLayout.vue";
import LocalizationSlot from "@/components/localization-tabs/LocalizationSlot.vue";
import { objectLocalizationSchemaOf } from "@/yup-extensions";

const componentTexts =
  texts.navigationItems.organize.activity.registrationLinks;

const props = defineProps<{
  formValues: RegistrationLinkEditFormValues;
}>();

const emit = defineEmits<{
  submit: [values: RegistrationLinkEditFormValues];
}>();

const form = useForm<RegistrationLinkEditFormValues>({
  validationSchema: yup.object({
    localizations: objectLocalizationSchemaOf(
      yup.object({
        name: yup.string().max(256).required(),
        prospectAppDescription: yup.string().max(1024),
      }),
    ),
    confirmationEmailTemplateId: yup.string(),
    registrationStartDateTime: yup.mixed<DateTime>(),
    registrationEndDateTime: yup
      .mixed<DateTime>()
      .isLaterThan(
        "registrationStartDateTime",
        texts.validation.endDateTimeMinimumStartDateTime,
      ),
  }),
});

const defaultCulture = settings.mainLanguage.locale.value as Culture;
const activeCulture = ref<Culture>(
  settings.mainLanguage.locale.value as Culture,
);
const locales = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

function addLocalization(culture: Culture) {
  form.setFieldValue("localizations", {
    ...form.values.localizations,
    [culture]: { name: "" },
  });
}

function removeLocalization(culture: Culture) {
  const newDict = { ...form.values.localizations };
  delete newDict[culture];
  form.setFieldValue("localizations", newDict);
}

watch(
  () => props.formValues,
  (values) => form.setValues(values),
  { immediate: true },
);

const submitForm = form.handleSubmit((values) => emit("submit", values));
</script>

import {
  ActivityDTO,
  ActivityExtendedDTO,
  ActivityStudyProgramUpdateDTO,
} from "@/models/activity";
import { SessionProgramSettingsUpdateDTO } from "@/models/session";
import { downloadFileResponse } from "@/utils/downloadFile";
import HttpClient from "@/utils/httpClient";
import { DateTime } from "luxon";

export interface ActivitiesQueryParams {
  name: string;
  activityTypeId: string;
  studyProgramIds: string[];
  offset: string;
  limit: string;
  minStartDateTime: string;
  maxStartDateTime: string;
  minEndDateTime: string;
  maxEndDateTime: string;
}

export const getActivities = async (
  activityTypeId: string | null = null,
  includeOldActivities = false,
  name: string | null = null,
  options?: Partial<ActivitiesQueryParams>,
): Promise<ActivityDTO[]> => {
  const params: Partial<ActivitiesQueryParams> = {
    minEndDateTime: !includeOldActivities
      ? DateTime.now().minus({ months: 1 }).toISODate()
      : undefined,
    activityTypeId: activityTypeId ? activityTypeId : undefined,
    name: name ? name : undefined,
    ...options,
  };

  return await HttpClient.educonfig
    .get<ActivityDTO[]>("activities", {
      params,
    })
    .then((response) => response.data);
};

export const getActivity = async (
  activityId: string,
): Promise<ActivityExtendedDTO> => {
  return await HttpClient.educonfig
    .get<ActivityExtendedDTO>(`activities/${activityId}`)
    .then((response) => response.data);
};

// Study Programs

export const updateActivityStudyPrograms = async (
  activityStudyProgramUpdateDTO: ActivityStudyProgramUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `activities/${activityStudyProgramUpdateDTO.id}/study-programs`,
    activityStudyProgramUpdateDTO,
  );
};

export const updateSessionProgramSettings = async (
  activityId: string,
  sessionProgramSettingsUpdateDTO: SessionProgramSettingsUpdateDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `activities/${activityId}/session-program-settings`,
    sessionProgramSettingsUpdateDTO,
  );
};

export const downloadSessionImportTemplate = async (
  activityId: string,
): Promise<void> => {
  await HttpClient.educonfig
    .get<Blob>(`activities/${activityId}/sessions/import/template`, {
      responseType: "blob",
    })
    .then((response) => downloadFileResponse(response));
};

export interface ImportResultDto {
  success: boolean;
  errors?: string[];
  importedSessionsCount: number;
}

export interface ActivitySMSCampaignDTO {
  message: string;
  plannedDateTime: string;
}

export const getActivitySMSCampaign = async (
  activityId: string,
): Promise<ActivitySMSCampaignDTO> => {
  return await HttpClient.educonfig
    .get(`activities/${activityId}/short-message-campaign`)
    .then((response) => response.data);
};

export const createActivitySMSCampaign = async (
  activityId: string,
  dto: ActivitySMSCampaignDTO,
): Promise<void> => {
  return await HttpClient.educonfig.post(
    `activities/${activityId}/short-message-campaign`,
    dto,
  );
};

export const updateActivitySMSCampaign = async (
  activityId: string,
  dto: ActivitySMSCampaignDTO,
): Promise<void> => {
  return await HttpClient.educonfig.put(
    `activities/${activityId}/short-message-campaign`,
    dto,
  );
};

export const deleteActivitySMSCampaign = async (
  activityId: string,
): Promise<void> => {
  return await HttpClient.educonfig.delete(
    `activities/${activityId}/short-message-campaign`,
  );
};

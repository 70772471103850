import { downloadFileResponse } from "@/utils/downloadFile";
import HttpClient from "@/utils/httpClient";

export const downloadConfigurationFile = async (
  activityId: string,
  locationId: string,
): Promise<void> => {
  return await HttpClient.educonfig
    .get<Blob>(
      `activities/${activityId}/scan-configurations/${locationId}/document`,
      {
        params: {
          activityId: activityId,
          locationId: locationId,
        },
        responseType: "blob",
      },
    )
    .then((response) => downloadFileResponse(response));
};

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="visibleInternal">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-10 overflow-y-auto"
      :open="visibleInternal"
      :data-testid="testIds.modal"
      @close="visibleInternal = false"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:align-middle"
            :class="[
              { relative: centered },
              { 'sm:max-w-lg': size === ModalSize.Normal },
              { 'sm:max-w-6xl': size === ModalSize.Large },
            ]"
          >
            <div class="flex flex-col gap-5">
              <div :class="[{ 'sm:flex sm:items-start': !centered }, 'gap-6']">
                <div
                  v-if="type == ModalType.danger || type == ModalType.warning"
                  :class="iconOuterClass"
                >
                  <Icon
                    icon="warning"
                    :class="iconInnerClass"
                    aria-hidden="true"
                  />
                </div>
                <div
                  v-if="title || description"
                  class="flex flex-col gap-3 text-center"
                  :class="[centered ? 'sm:text-center' : 'sm:text-left']"
                >
                  <DialogTitle
                    v-if="title"
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                    data-testid="modal-title"
                  >
                    {{ title }}
                  </DialogTitle>
                  <div v-if="description || $slots.description">
                    <p class="text-sm text-gray-500">
                      {{ description }}
                      <slot name="description"></slot>
                    </p>
                  </div>
                </div>
              </div>
              <ModalContent>
                <slot name="content"></slot>
                <slot name="buttons">
                  <!-- Default button because Dialog-component requires a button element to focus-->
                  <button></button>
                </slot>
              </ModalContent>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { computed } from "vue";
import { ModalType, ModalSize } from "@/components/common/modal/Modal.types";
import ModalContent from "@/components/common/modal/ModalContent.vue";
import { testIds } from "@/utils/testing";
import Icon from "@/components/common/icon/Icon.vue";

const props = withDefaults(
  defineProps<{
    visible: boolean;
    type?: ModalType;
    title?: string;
    description?: string;
    centered?: boolean;
    size?: ModalSize;
  }>(),
  {
    type: undefined,
    icon: undefined,
    title: undefined,
    description: undefined,
    centered: false,
    size: ModalSize.Normal,
  },
);

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
}>();

const visibleInternal = computed({
  get: () => props.visible,
  set: (value) => emit("update:visible", value),
});

const iconOuterClass = computed(() => [
  "mx-auto flex items-center justify-center h-12 w-12 rounded-full my-2",
  { "flex-shrink-0 sm:mx-0 sm:h-10 sm:w-10": !props.centered },
  {
    "bg-alert-100": props.type === ModalType.danger,
    "bg-warning-100": props.type === ModalType.warning,
  },
]);
const iconInnerClass = computed(() => [
  {
    "text-alert-600": props.type === ModalType.danger,
    "text-warning-600": props.type === ModalType.warning,
  },
]);
</script>

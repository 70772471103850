<template>
  <form class="flex flex-col gap-8" @submit="submitForm">
    <LocalizationTabs
      v-slot="{ current }"
      v-model="activeCulture"
      :locales
      @add="addLocale"
      @remove="removeLocale"
    >
      <LocalizationSlot v-slot="{ locale }" :current :locales>
        <FormFieldTextInput
          :id="`localizations.${locale}.name`"
          :label="texts.models.activity.name"
          :displayMode="FormFieldDisplayMode.Row"
        />
        <FormFieldWysiwyg
          :id="`localizations.${locale}.prospectAppDescription`"
          :label="texts.navigationItems.organize.activity.form.description"
          :displayMode="FormFieldDisplayMode.Row"
        />
      </LocalizationSlot>
    </LocalizationTabs>
    <Divider />
    <FormFieldSelect
      id="typeId"
      :label="texts.navigationItems.organize.activity.form.type"
      :items="selectItems"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldDateTime
      id="startDateTime"
      :label="texts.navigationItems.organize.activity.form.startDateTime"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldDateTime
      id="endDateTime"
      :label="texts.navigationItems.organize.activity.form.endDateTime"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldToggle
      id="isOnline"
      :label="texts.navigationItems.organize.activity.form.isOnline"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldTextInput
      v-if="form.values.isOnline"
      id="onlineUrl"
      :label="texts.navigationItems.organize.activity.form.onlineUrl"
      :type="TextInputType.URL"
      :displayMode="FormFieldDisplayMode.Row"
    />
    <FormFieldTextInput
      id="capacity"
      :label="texts.navigationItems.organize.activity.form.capacity"
      :type="TextInputType.NUMBER"
      :displayMode="FormFieldDisplayMode.Row"
    />

    <SectionDivider />
    <slot name="actions" />
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import * as yup from "yup";

import { FormFieldDisplayMode } from "@/components/common/form/FormField.types";
import SectionDivider from "@/components/common/section/SectionDivider.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";

import { Culture } from "@/enums";
import texts from "@/utils/texts";
import { ActivitySettingsFormValues } from "./ActivitySettings.types";
import settings from "@/store/context/settings.context";
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldWysiwyg from "@/components/common/wysiwyg/FormFieldWysiwyg.vue";
import { DateTime } from "luxon";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import LocalizationSlot from "@/components/localization-tabs/LocalizationSlot.vue";
import Divider from "@/components/common/divider/Divider.vue";
import { objectLocalizationSchemaOf } from "@/yup-extensions";

const defaultCulture = settings.mainLanguage.locale.value as Culture;

const props = defineProps<{
  formValues?: ActivitySettingsFormValues;
}>();

const emit = defineEmits<{
  submit: [values: ActivitySettingsFormValues];
}>();

const locales = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

// Form configuration
const validationSchema = yup.object({
  localizations: objectLocalizationSchemaOf(
    yup.object({
      name: yup.string().max(256).required(),
      prospectAppDescription: yup.string().max(1024),
    }),
  ),
  startDateTime: yup.mixed<DateTime>().required(),
  endDateTime: yup
    .mixed<DateTime>()
    .required()
    .isLaterThan(
      "startDateTime",
      texts.validation.endDateTimeMinimumStartDateTime,
    ),
  typeId: yup.string().required(),
  isOnline: yup.boolean().required().default(false),
  onlineUrl: yup.string().when("isOnline", {
    is: true,
    then: (schema) => schema.url().required(),
  }),
  capacity: yup.number(),
  isVisibleForProspects: yup.boolean().required().default(false),
});

const form = useForm<ActivitySettingsFormValues>({
  validationSchema,
});

const selectItems = settings.activityTypes.map((at) => ({
  label: at.name,
  value: at.id,
}));

const activeCulture = ref<Culture>(
  settings.mainLanguage.locale.value as Culture,
);

function addLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    addToLocalizations(form.values.localizations, culture, {
      name: "",
      prospectAppDescription: "",
    }),
  );
}

function removeLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    removeFromLocalizations(form.values.localizations, culture),
  );
}

function addToLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
  value: T,
) {
  return { ...localizations, [culture]: value };
}

function removeFromLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
) {
  const newDict = { ...localizations };
  delete newDict[culture];
  return newDict;
}

const submitForm = form.handleSubmit((values) => {
  emit("submit", values);
});

watch(
  () => props.formValues,
  (values) => {
    if (values) {
      form.setValues(values);
    }
  },
  { immediate: true },
);
</script>
